import { t } from "../functions/t";
import { clearElement } from "../shared/clear-element";
export function loadingButtonController(buttonElement, loading$) {
    var _a;
    buttonElement.classList.add('mip-loadingIndicator__button');
    const text = buttonElement.textContent;
    const textElement = document.createElement('span');
    textElement.classList.add('mip-loadingIndicator__text');
    textElement.innerText = (_a = text === null || text === void 0 ? void 0 : text.trim()) !== null && _a !== void 0 ? _a : '';
    const loadingIndicatorElement = document.createElement('span');
    loadingIndicatorElement.classList.add('mip-loadingIndicator');
    //language=HTML
    loadingIndicatorElement.innerHTML = `
    <span class="sr-only">${t('ui.loading')}</span>
  `;
    clearElement(buttonElement);
    buttonElement.append(textElement);
    buttonElement.append(loadingIndicatorElement);
    loading$.subscribe(loading => {
        if (loading) {
            buttonElement.classList.add('loading');
        }
        else {
            buttonElement.classList.remove('loading');
        }
    });
}
