import { always } from "asrt";
import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { fromEvent } from "rxjs";
export function burgerController(element) {
    always(isInstanceOf(HTMLButtonElement, element), "[9rE-dLH-WYX]");
    const targetName = element.getAttribute('data-target');
    const target = document.querySelectorAll(`[data-target="${targetName}"]:not([data-controller="NavbarBurger"])`);
    const html = document.querySelector('html');
    always(isInstanceOf(HTMLHtmlElement, html), "[dp3-rFM-3wK]");
    // toggle class is-active on click, on target end the button
    fromEvent(element, 'click').subscribe(() => {
        element.classList.toggle('is-active');
        html.classList.toggle('sidebar-open');
        target.forEach(t => {
            always(isInstanceOf(HTMLElement, t), "[D3r-3XT-ULY]");
            t.classList.toggle('is-active');
        });
    });
    // remove class is-active on click on anything else than the burger or the target
    fromEvent(document, 'click').subscribe(e => {
        if (e.target instanceof HTMLElement) {
            if (!e.target.matches(`[data-target=${targetName}]`)
                && e.target.closest(`[data-target=${targetName}]`) == null) {
                element.classList.remove('is-active');
                html.classList.remove('sidebar-open');
                target.forEach(t => {
                    always(isInstanceOf(HTMLElement, t), "[fdU-Fvd-xF3]");
                    t.classList.remove('is-active');
                });
            }
        }
    });
}
