import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { always } from "asrt";
import { debounceTime, fromEvent } from "rxjs";
export function submitOnChange(formElement) {
    always(isInstanceOf(HTMLFormElement, formElement), "[TrL-WdF-azE]");
    const id = formElement.id;
    // inner inputs
    const innerInputs = Array.from(formElement.querySelectorAll('input, select, textarea'));
    // outer inputs
    const outerInputs = Array.from(document.querySelectorAll(`
    input[form="${id}"],
    select[form="${id}"],
    textarea[form="${id}"]
  `));
    const inputs = [...innerInputs, ...outerInputs];
    inputs.forEach(input => {
        fromEvent(input, 'change').pipe(debounceTime(50)).subscribe(ev => {
            formElement.requestSubmit();
        });
    });
}
