var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { always } from "asrt";
import { isString } from "@pzapalski/ts-utils/dist/guards/is-string";
import { t } from "../../functions/t";
export function paczkomatPointController(element) {
    always(isInstanceOf(HTMLElement, element), "[ztb-kKP-tJR]");
    const name = element.getAttribute('data-name');
    always(isString(name), "[k3F-h3F-wfw]");
    (() => __awaiter(this, void 0, void 0, function* () {
        const host = 'https://api.inpost.pl';
        const uri = '/v1/points';
        const token = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMjQ3NDcwMzIsImlhdCI6MTcwOTM4NzAzMiwianRpIjoiMmM0ZTNlYzctNDU5Yi00ZWM4LTllZjQtYzM3OTM5YjA5MGNlIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpTeTF3YXlULXVYSEtzeEExVDBRN0xjVkVzMzhpN3Y5Ui14VzcxbDBaYk1BIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiMWU2MWNhMGMtMjM3MC00NGVhLWEyMWItMGZiMzY4MTRhMzUyIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyBhcGk6c2hpcHgiLCJzaWQiOiIxZTYxY2EwYy0yMzcwLTQ0ZWEtYTIxYi0wZmIzNjgxNGEzNTIiLCJhbGxvd2VkX3JlZmVycmVycyI6IiIsInV1aWQiOiIzMDBjMDRjZC04NDE5LTQ1OGItYmJhOC1kOTYzZmNlOTlmMzIiLCJlbWFpbCI6Im11c3psZWlwZXJseUBnbWFpbC5jb20ifQ.BztYagxHrLS3xe52h_0J6WekIxmOXwivm3VssgZ-ehIPjDsiimebereeoqu03FRaQxI2GWKEUDdxAMVEGamyrrvrngI1gWv9KWNcDsNx4cioxCY7nrkdJiBuJWlQ5m2AUAQAKxTxyJEh78p6u5q7K_oT0dPvoPiV615M8-wyKVQlt56oR9u0ZQiLvU0sOx-mLCoqpT-g1_LL8pMcFsH3-U-YIlr7r9_uUIRvDc9rHvoGdyquqmZn1qJ-Fq07O1gbnbqxEm4LC7vrFeNl7sHwYsLBrcXZFQXgOEyAGcbLphK4XnJNCmR7OwyM8K9l9y4Aak-oXR4JMStOz0AW4wZzCA';
        const response = yield fetch(`${host}${uri}?name=${name}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const data = yield response.json();
        const point = data.items[0];
        element.innerHTML = `
        <h4 class="mip-paczkomatPoint__name">${point.name}</h4>
        <span>${t("paczkomat.openingHours")}: <strong>${point.opening_hours}</strong></span>
        <p class="mip-paczkomatPoint__address">
            ${point.address_details.street}
            ${point.address_details.building_number}<!--
            -->${point.address_details.flat_number ? `/${point.address_details.flat_number}` : ''},
            ${point.address_details.city}
        </p>
        <p class="mip-paczkomatPoint__description">
            ${point.location_description}
            ${point.location_description_1 ? `<br>${point.location_description_1}` : ''}
            ${point.location_description_2 ? `<br>${point.location_description_2}` : ''}
        </p>
    `;
    }))();
}
