import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { isString } from "@pzapalski/ts-utils/dist/guards/is-string";
import { always } from "asrt";
export function increaseButton(element) {
    always(element instanceof HTMLElement, "[3WR-WCF-jX3]");
    const targetId = element.getAttribute("data-target");
    always(isString(targetId), "[Ka9-YwH-p7P]");
    const target = document.getElementById(targetId);
    always(isInstanceOf(HTMLInputElement, target), "[v3L-kfh-azy]");
    element.addEventListener("click", () => {
        const value = parseInt(target.value);
        const minAttr = target.getAttribute("min");
        const maxAttr = target.getAttribute("max");
        const min = minAttr ? parseInt(minAttr) : -Infinity;
        const max = maxAttr ? parseInt(maxAttr) : Infinity;
        target.value = (Math.min(max, Math.max(min, value + 1))).toString();
    });
}
export function decreaseButton(element) {
    always(element instanceof HTMLElement, "[Luz-cxt-Txj]");
    const targetId = element.getAttribute("data-target");
    always(isString(targetId), "[bDw-wVk-Ksb]");
    const target = document.getElementById(targetId);
    always(isInstanceOf(HTMLInputElement, target), "[9hD-Y39-VNt]");
    element.addEventListener("click", () => {
        const value = parseInt(target.value);
        const minAttr = target.getAttribute("min");
        const maxAttr = target.getAttribute("max");
        const min = minAttr ? parseInt(minAttr) : -Infinity;
        const max = maxAttr ? parseInt(maxAttr) : Infinity;
        target.value = (Math.min(max, Math.max(min, value - 1))).toString();
    });
}
