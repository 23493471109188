import { always } from "asrt";
import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { BehaviorSubject, fromEvent } from "rxjs";
import { loadingButtonController } from "../loading-button.controller";
export function checkoutSummaryController(element) {
    always(isInstanceOf(HTMLElement, element), "[LJz-vjt-RfP]");
    const submitButtonElement = element.querySelector(`[data-element=CheckoutSubmit]`);
    always(isInstanceOf(HTMLButtonElement, submitButtonElement), "[eeW-svr-3cr]");
    const loading$ = new BehaviorSubject(false);
    fromEvent(submitButtonElement, 'click').subscribe(() => {
        loading$.next(true);
    });
    loadingButtonController(submitButtonElement, loading$);
}
