import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { always } from "asrt";
import { fromEvent, merge, throttleTime } from "rxjs";
export function cartWidgetController2(wrapper, open$) {
    const cartWidgetButtonElement = wrapper.querySelector('#CartWidgetButton');
    const frameElement = wrapper.querySelector('.mip-cartWidget__frame');
    always(isInstanceOf(HTMLElement, cartWidgetButtonElement), "[NzL-aE7-zjH]");
    always(isInstanceOf(HTMLElement, frameElement), "[M9r-EXb-zsh]");
    fromEvent(cartWidgetButtonElement, 'click').subscribe(() => {
        toggleWidget();
    });
    open$.subscribe(open => {
        toggleWidget(open);
    });
    const toggleWidget = (open) => {
        frameElement.classList.toggle('hidden', open == null ? undefined : !open);
        // if query has "cart=open" remove it
        const url = new URL(window.location.href);
        if (url.searchParams.has('cart')) {
            url.searchParams.delete('cart');
            window.history.replaceState(null, '', url.toString());
        }
    };
    merge(fromEvent(document, 'click'), fromEvent(document, 'focus'), fromEvent(document, 'wheel'), fromEvent(document, 'scroll'), fromEvent(document, 'touchend')).pipe(throttleTime(100)).subscribe(e => {
        if (!frameElement.classList.contains('hidden')) {
            if (e.target instanceof HTMLElement) {
                if (e.target.isEqualNode(frameElement)
                    || frameElement.contains(e.target)
                    || e.target.isEqualNode(cartWidgetButtonElement)) {
                    return;
                }
                toggleWidget(false);
            }
        }
    });
}
