import { debounceTime, fromEvent, Subject } from "rxjs";
import { always } from "asrt";
import { burgerController } from "./controllers/navigation/burger.controller";
import { checkoutInfoController } from "./controllers/checkout/checkout-info.controller";
import { checkoutSummaryController } from "./controllers/checkout/checkout-summary.controller";
import { checkoutConfirmationController } from "./controllers/checkout/checkout-confirmation.controller";
import { checkoutPaczkomatController } from "./controllers/checkout/checkout-paczkomat.controller";
import { paczkomatPointController } from "./controllers/checkout/paczkomat-point.controller";
import { startWith } from "rxjs/operators";
import { contactFormController } from "./controllers/contact-form.controller";
import { cartWidgetController2 } from "./controllers/cart-widget/cart-widget.controller2";
import { decreaseButton, increaseButton } from "./controllers/increase-decrease-button.controller";
import { submitOnChange } from "./controllers/submit-on-change";
document.addEventListener('DOMContentLoaded', () => {
    const html = document.querySelector('html');
    if (html == null) {
        return;
    }
    html.classList.remove('no-js');
    html.classList.add('js');
    const openCartWidget$ = new Subject();
    // document.querySelectorAll(`form[data-controller=AddToCartForm]`).forEach(form => {
    //   always(form instanceof HTMLFormElement, "[7E3-Vfz-FRv]");
    //   addToCartFormController(form, openCartWidget$);
    // });
    document.querySelectorAll(`[data-controller=SubmitOnChange]`).forEach(el => {
        always(el instanceof HTMLFormElement, "[E3Y-Tce-p3W]");
        submitOnChange(el);
    });
    document.querySelectorAll(`[data-controller=IncreaseButton]`).forEach(el => {
        always(el instanceof HTMLButtonElement, "[rvp-tNW-9Ew]");
        increaseButton(el);
    });
    document.querySelectorAll(`[data-controller=DecreaseButton]`).forEach(el => {
        always(el instanceof HTMLButtonElement, "[cfa-Wrx-ssR]");
        decreaseButton(el);
    });
    document.querySelectorAll(`[data-controller=CartWidget]`).forEach(el => {
        always(el instanceof HTMLElement, "[DHp-WeT-dRe]");
        cartWidgetController2(el, openCartWidget$);
    });
    // document.querySelectorAll(`[data-controller=CartWidget]`).forEach(el => {
    //   always(el instanceof HTMLElement, "[DHp-WeT-dRe]");
    //   cartWidgetController(el, openCartWidget$);
    // });
    // document.querySelectorAll(`[data-controller=CartForm]`).forEach(el => {
    //   always(el instanceof HTMLFormElement, "[tkM-UWU-M7P]");
    //   cartFormController(el);
    // });
    document.querySelectorAll(`[data-controller=CheckoutInfo]`).forEach(el => {
        always(el instanceof HTMLFormElement, "[dKf-7tw-eYU]");
        checkoutInfoController(el);
    });
    document.querySelectorAll(`[data-controller=CheckoutConfirmation]`).forEach(el => {
        always(el instanceof HTMLFormElement, "[Hjf-Re9-hLp]");
        checkoutConfirmationController(el);
    });
    document.querySelectorAll(`[data-controller=CheckoutSummary]`).forEach(el => {
        always(el instanceof HTMLElement, "[b7X-wcR-VJ7]");
        checkoutSummaryController(el);
    });
    document.querySelectorAll(`[data-controller=NavbarBurger]`).forEach(el => {
        always(el instanceof HTMLButtonElement, "[hjU-3cM-kfp]");
        burgerController(el);
    });
    document.querySelectorAll(`[data-controller=PaczkomatSelector]`).forEach(el => {
        always(el instanceof HTMLElement, "[Ljx-Vup-XsK]");
        checkoutPaczkomatController(el);
    });
    document.querySelectorAll(`[data-controller=PaczkomatPoint]`).forEach(el => {
        always(el instanceof HTMLElement, "[bR3-33f-EUw]");
        paczkomatPointController(el);
    });
    document.querySelectorAll(`[data-controller=ContactForm]`).forEach(el => {
        always(el instanceof HTMLFormElement, "[HMv-aJM-tpN]");
        contactFormController(el);
    });
});
fromEvent(document, 'scroll').pipe(debounceTime(10), startWith(null)).subscribe(() => {
    var _a, _b;
    const html = document.querySelector('html');
    if (html == null) {
        return;
    }
    const headerHeight = (_b = (_a = document.querySelector('.mip-header__wrapper')) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : 0;
    const height = window.innerHeight - headerHeight;
    if (window.scrollY > height) {
        html.classList.add('scrolled-below-fold');
        html.classList.remove('scrolled-above-fold');
    }
    else {
        html.classList.remove('scrolled-below-fold');
        html.classList.add('scrolled-above-fold');
    }
    if (window.scrollY > 0) {
        html.classList.add('scrolled');
        html.classList.remove('not-scrolled');
    }
    else {
        html.classList.remove('scrolled');
        html.classList.add('not-scrolled');
    }
});
