var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject, map } from "rxjs";
import { isObject } from "@pzapalski/ts-utils/dist/guards/is-object";
import { isString } from "@pzapalski/ts-utils/dist/guards/is-string";
import { isNull } from "@pzapalski/ts-utils/dist/guards/is-null";
import { always } from "asrt";
const PACZKOMAT_STORAGE_KEY = 'Paczkomat';
export const PACZKOMAT_TOKEN = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMjEwMzAxMzYsImlhdCI6MTcwNTY3MDEzNiwianRpIjoiMDBhZmIzODEtMDk5ZS00ODI2LWI4MzktYzhmMjc5NzViZTA0IiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpTeTF3YXlULXVYSEtzeEExVDBRN0xjVkVzMzhpN3Y5Ui14VzcxbDBaYk1BIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiNDFmNWNlZDQtMzBjYS00YWE4LWI3MmYtOGUzMTI4MGVkMWNmIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjQxZjVjZWQ0LTMwY2EtNGFhOC1iNzJmLThlMzEyODBlZDFjZiIsImFsbG93ZWRfcmVmZXJyZXJzIjoiIiwidXVpZCI6IjMwMGMwNGNkLTg0MTktNDU4Yi1iYmE4LWQ5NjNmY2U5OWYzMiJ9.gZ1SZcaAotD8PmDO8GpD8ujGnYkLaHHAUTl7zCRI5QBcvrpq8GkvraLZm4lZzVc7_ZCz-XbNNcFqJBdj8fymo3jFtq9DyVaU8d7VcBJ3m1ZgWmlDH1l7ytg7f070U_NoWvSVVeDqch6sZED0ePPUs5dI4YFFtKgUltNZ7kV6xpkrjICrgLxwNL53BLxGJOwoo-qfo_eYGfZxTkoB5CtTH59kBBKM-DNjb5L3yEv38OAihTckQz0AFJQikkTZPyV9_BXSzKF0iZhKV2mPR1jr8altRdGdhuj-dKTCb1nfECHlQNz027cgIYHyXD6pafP7d2tdUAPf2oGs3jxEFk7VLQ";
export class PaczkomatRepository {
    constructor() {
        this.sync$ = new BehaviorSubject(null);
    }
    save(pointId) {
        always(isString(pointId), "[LLU-dLK-tLL]");
        localStorage.setItem(PACZKOMAT_STORAGE_KEY, JSON.stringify(pointId));
        this.sync$.next(null);
    }
    clear() {
        localStorage.removeItem(PACZKOMAT_STORAGE_KEY);
        this.sync$.next(null);
    }
    getPointId$() {
        return this.sync$.pipe(map(() => this.getPointId()));
    }
    getPointId() {
        const pointString = localStorage.getItem(PACZKOMAT_STORAGE_KEY);
        if (pointString == null) {
            return null;
        }
        return JSON.parse(pointString);
    }
    getPointDetails(id) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const url = "https://api.inpost.pl/v1/points?name=" + encodeURIComponent(id);
            const res = yield fetch(url, {
                method: "GET",
                headers: {
                    ContentType: "application/json",
                    Authorization: "Bearer " + PACZKOMAT_TOKEN
                }
            });
            const json = yield res.json();
            const point = (_a = json.items) === null || _a === void 0 ? void 0 : _a[0];
            if (isPaczkomatPoint(point)) {
                return point;
            }
            return null;
        });
    }
    static get instance() {
        let i = PaczkomatRepository._instance;
        if (i == null) {
            i = new PaczkomatRepository();
            PaczkomatRepository._instance = i;
        }
        return i;
    }
}
export function isPaczkomapEvent(obj) {
    if (!isObject(obj)) {
        return false;
    }
    const { detail } = obj;
    return isObject(detail) && isPaczkomatApi(detail.api);
}
export function isPaczkomatApi(obj) {
    if (!isObject(obj)) {
        return false;
    }
    const { addPointSelectedCallback } = obj;
    return typeof addPointSelectedCallback === 'function';
}
export function isPaczkomatPoint(obj) {
    if (!isObject(obj)) {
        return false;
    }
    const { name, location_description, location_description_1, location_description_2, opening_hours, address_details } = obj;
    return isString(name)
        && (isNull(location_description) || isString(location_description))
        && (isNull(location_description_1) || isString(location_description_1))
        && (isNull(location_description_2) || isString(location_description_2))
        && (isNull(opening_hours) || isString(opening_hours))
        && isPaczkomatPointAddressDetails(address_details);
}
function isPaczkomatPointAddressDetails(obj) {
    if (!isObject(obj)) {
        return false;
    }
    const { city, province, post_code, street, building_number, flat_number } = obj;
    return isString(city)
        && isString(province)
        && isString(post_code)
        && isString(street)
        && isString(building_number)
        && (isNull(flat_number) || isString(flat_number));
}
