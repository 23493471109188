import { always } from "asrt";
import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { PaczkomatRepository, isPaczkomapEvent, isPaczkomatPoint } from "../../services/paczkomat-repository";
import { fromEvent, of } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { t } from "../../functions/t";
export function checkoutPaczkomatController(element) {
    always(isInstanceOf(HTMLElement, element), "[WNE-T9b-fua]");
    const targetElement = element.querySelector('[data-element=PaczkomatTarget]');
    const modalElement = element.querySelector('[data-element=Modal]');
    const inputElement = element.querySelector('#s-c-id');
    const paczkomatRepository = PaczkomatRepository.instance;
    always(isInstanceOf(HTMLElement, targetElement), "[Khp-at3-7Hz]");
    always(isInstanceOf(HTMLElement, modalElement), "[Xhc-EFM-aXP]");
    always(isInstanceOf(HTMLInputElement, inputElement), "[vyU-rXH-fbW]");
    const pointId$ = fromEvent(inputElement, 'input').pipe(startWith(null), map(() => inputElement.value));
    pointId$.pipe(switchMap(pointId => pointId ? paczkomatRepository.getPointDetails(pointId) : of(null))).subscribe(point => {
        if (point) {
            targetElement.innerHTML = `
        <div class="mip-paczkomatPoint__wrapper">
          <article class="mip-paczkomatPoint">
              <h4 class="mip-paczkomatPoint__name">${point.name}</h4>
              <span>${t("paczkomat.openingHours")}: <strong>${point.opening_hours}</strong></span>
              <p class="mip-paczkomatPoint__address">
                  ${point.address_details.street}
                  ${point.address_details.building_number}<!--
                  -->${point.address_details.flat_number ? `/${point.address_details.flat_number}` : ''},
                  ${point.address_details.city}
              </p>
              <p class="mip-paczkomatPoint__description">
                  ${point.location_description}
                  ${point.location_description_1 ? `<br>${point.location_description_1}` : ''}
                  ${point.location_description_2 ? `<br>${point.location_description_2}` : ''}
              </p>
          </article>
          <div class="mip-paczkomatPoint__buttonWrapper">
            <button class="mip-paczkomatPoint__button button" data-element="ModalOpen" data-modal="map" type="button">
              ${t("paczkomat.selectOther")}
            </button>
          </div>
        </div>
      `;
            inputElement.value = point.name;
        }
        else {
            targetElement.innerHTML = `
        <button class="button button--primary" data-element="ModalOpen" data-modal="map">
            ${t("checkout.carrier.inpost.text.choose-on-map")}
        </button>
      `;
            inputElement.value = '';
        }
    });
    element.addEventListener('inpost.geowidget.init', (event) => {
        if (isPaczkomapEvent(event)) {
            event.detail.api.addPointSelectedCallback((point) => {
                always(isPaczkomatPoint(point), "[FvJ-7Fs-XtJ]");
                inputElement.value = point.name;
                inputElement.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
                modalElement.classList.remove('active');
                // const targetPos = {
                //   x: targetElement.offsetLeft,
                //   y: targetElement.offsetTop
                // }
                // scrollTo(targetPos.x, targetPos.y);
            });
        }
    });
    element.addEventListener('click', (event) => {
        var _a, _b;
        const target = event.target;
        if (((_a = target === null || target === void 0 ? void 0 : target.dataset) === null || _a === void 0 ? void 0 : _a.element) === 'ModalClose') {
            event.preventDefault();
            const modalElement = target.closest('.mip-modal__wrapper');
            if (modalElement) {
                modalElement.classList.remove('active');
            }
        }
        if (((_b = target === null || target === void 0 ? void 0 : target.dataset) === null || _b === void 0 ? void 0 : _b.element) === 'ModalOpen') {
            event.preventDefault();
            const modalId = target.dataset.modal;
            const modalElement = element.querySelector(`[data-element=Modal][data-modal=${modalId}]`);
            if (modalElement) {
                modalElement.classList.add('active');
            }
        }
    });
}
