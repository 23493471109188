var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { always } from "asrt";
import { formValue$ } from "../../shared/form-value";
import { BehaviorSubject, fromEvent, map, tap } from "rxjs";
import { isInstanceOf } from "@pzapalski/ts-utils/dist/guards/is-instance-of";
import { loadingButtonController } from "../loading-button.controller";
export function checkoutInfoController(formElement) {
    const billingFieldsetElement = formElement.querySelector(`[data-element=BillingFieldset]`);
    const shippingFieldsetElement = formElement.querySelector(`[data-element=ShippingFieldset]`);
    const consentFieldsetElement = formElement.querySelector(`[data-element=ConsentFieldset]`);
    always(isInstanceOf(HTMLFieldSetElement, billingFieldsetElement), "[dWk-hKR-Yss]");
    always(isInstanceOf(HTMLFieldSetElement, shippingFieldsetElement), "[cYf-PLN-MEa]");
    always(isInstanceOf(HTMLFieldSetElement, consentFieldsetElement), "[hFP-T9e-kxJ]");
    const submitButtonElements = Array.from(formElement.querySelectorAll(`[data-element=CheckoutSubmit]`));
    submitButtonElements.forEach(e => always(isInstanceOf(HTMLButtonElement, e), "[teW-kU3-WkD]"));
    const backButtonElements = Array.from(formElement.querySelectorAll(`[data-element=CheckoutBack]`));
    backButtonElements.forEach(e => always(isInstanceOf(HTMLButtonElement, e), "[thE-EHu-VzF]"));
    formElement.noValidate = true;
    const controlElements = Array.from(formElement.querySelectorAll(`[name]`));
    controlElements.forEach(e => always((() => {
        return e instanceof HTMLInputElement
            || e instanceof HTMLSelectElement
            || e instanceof HTMLTextAreaElement;
    })(), "[JLY-U3P-xYs]"));
    const submit$ = fromEvent(formElement, 'submit').pipe(tap(e => {
        e.preventDefault();
    }));
    const loading$ = new BehaviorSubject(false);
    // toggle shipping info section
    const otherShippingFieldElements = [
        'b[fnm]', 'b[lnm]',
        'b[cty]', 'b[str]', 'b[zip]',
        'b[phn]', 'b[eml]'
    ].map(name => {
        const inputElement = billingFieldsetElement.querySelector(`[name="${name}"]`);
        always(inputElement != null, "[9Rs-NVF-vwE]");
        const fieldElement = inputElement.closest('.field');
        always(fieldElement != null, "[wtx-NXV-pCx]");
        return {
            name,
            inputElement,
            fieldElement
        };
    });
    formValue$(billingFieldsetElement).pipe(map(v => v.get('b[oth]') === 'on')).subscribe(other => {
        otherShippingFieldElements.forEach(f => {
            const { inputElement, fieldElement } = f;
            if (other) {
                fieldElement.classList.add('required');
                inputElement.required = true;
                fieldElement.classList.remove('disabled');
                inputElement.removeAttribute('disabled');
                fieldElement.classList.remove('hidden');
                inputElement.classList.add('hidden');
            }
            else {
                fieldElement.classList.remove('required');
                inputElement.required = false;
                fieldElement.classList.add('disabled');
                inputElement.setAttribute('disabled', 'true');
                fieldElement.classList.add('hidden');
                inputElement.classList.add('hidden');
            }
        });
    });
    const disableForm = () => {
        submitButtonElements.forEach(se => se.disabled = true);
        billingFieldsetElement.setAttribute('disabled', 'true');
        shippingFieldsetElement.setAttribute('disabled', 'true');
        consentFieldsetElement.setAttribute('disabled', 'true');
    };
    const enableForm = () => {
        submitButtonElements.forEach(se => se.disabled = false);
        billingFieldsetElement.removeAttribute('disabled');
        shippingFieldsetElement.removeAttribute('disabled');
        consentFieldsetElement.removeAttribute('disabled');
    };
    // validate on input blur or submit
    submit$.subscribe(() => {
        controlElements.forEach(c => {
            validate(c);
        });
    });
    controlElements.forEach(c => {
        const field = c.closest('.field');
        if (field == null) {
            return;
        }
        fromEvent(c, 'blur').subscribe(() => {
            field.classList.add('dirty');
            validate(c);
        });
    });
    // capture form submit
    submit$.subscribe(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        // every control must be valid and s-c-id mustnt be empty
        if (controlElements.every(c => c.validity.valid) && ((_a = controlElements.find(c => c.name === 's-c-id')) === null || _a === void 0 ? void 0 : _a.value.trim()) !== '') {
            loading$.next(true);
            formElement.submit();
        }
    }));
    // display loading indicator on submit button
    submitButtonElements.forEach(se => loadingButtonController(se, loading$));
    //
    const validate = (control) => {
        const fieldElement = control.closest('.field');
        if (fieldElement == null) {
            return;
        }
        control.setCustomValidity('');
        const validity = control.validity;
        if (validity.valid) {
            fieldElement.classList.remove('invalid');
        }
        else {
            fieldElement.classList.add('invalid');
        }
    };
}
