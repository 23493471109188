import { debounceTime, distinctUntilChanged, fromEvent, map, merge } from "rxjs";
import { startWith } from "rxjs/operators";
import { equals } from "ramda";
export function formValue(formElement) {
    const controls = Array.from(formElement.querySelectorAll('[name]'));
    const vs = controls.map(c => {
        if (c.type === 'radio' && !c.checked) {
            return [null, null];
        }
        const v = c.type === 'checkbox' || c.type === 'radio'
            ? c.checked
                ? c.value
                : ''
            : c.value;
        return [c.getAttribute('name'), v];
    });
    const map = new Map(vs);
    map.delete(null);
    return map;
}
export function formValue$(formElement) {
    return merge(fromEvent(formElement, 'change'), fromEvent(formElement, 'keyup'), fromEvent(formElement, 'blur'), fromEvent(formElement, 'submit')).pipe(debounceTime(10), startWith(null), map(() => formValue(formElement)), distinctUntilChanged((x, y) => equals(x, y)));
}
