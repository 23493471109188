var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { always } from "asrt";
import { isString } from "@pzapalski/ts-utils/dist/guards/is-string";
/**
 * Translation function
 * @param {string} code
 * @returns {string}
 * */
export const t = translate(document.querySelector('[data-source=Translations]'));
const codesRequested = new Set();
/**
 * Prepare translations.
 * When source element doesn't exist OR when the source data is mangled JSON, returns the code
 * When the code can't be found in the source data:
 * - if the code is already in codesRequested set, just returns the code
 * - otherwise it also pushes the code into codesRequested set
 * - and sends the code to server for logging
 * */
function translate(source) {
    var _a;
    if (source == null) {
        return (code) => code;
    }
    const sourceText = ((_a = (source.textContent)) === null || _a === void 0 ? void 0 : _a.trim()) === '' ? '{}' : source.textContent;
    always(isString(sourceText), "[jkT-x9X-yCW]");
    const data = JSON.parse(sourceText);
    return (code, lang) => {
        var _a;
        lang = lang !== null && lang !== void 0 ? lang : document.documentElement.lang;
        const translation = (_a = data[code]) === null || _a === void 0 ? void 0 : _a[lang];
        if (translation == null) {
            if (codesRequested.has(code)) {
                return code;
            }
            codesRequested.add(code);
            (() => __awaiter(this, void 0, void 0, function* () {
                return yield fetch('/api/v1/translations', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ code, lang })
                });
            }))();
            return code;
        }
        return translation;
    };
}
