import { BehaviorSubject, fromEvent } from "rxjs";
import { loadingButtonController } from "./loading-button.controller";
import { always } from "asrt";
export function contactFormController(formElement) {
    const controlElements = Array.from(formElement.querySelectorAll('input, textarea, select'));
    const loading$ = new BehaviorSubject(false);
    const submitElements = Array.from(formElement.querySelectorAll('button[type=submit]'));
    formElement.noValidate = true;
    submitElements.forEach(se => {
        always(se instanceof HTMLButtonElement, "[VMv-pJF-JRe]");
        loadingButtonController(se, loading$);
    });
    formElement.addEventListener('submit', e => {
        loading$.next(true);
        controlElements.forEach(c => {
            validate(c);
        });
        if (!controlElements.every(c => c.validity.valid)) {
            e.preventDefault();
            loading$.next(false);
        }
    });
    controlElements.forEach(c => {
        const field = c.closest('.field');
        if (field == null) {
            return;
        }
        fromEvent(c, 'blur').subscribe(() => {
            field.classList.add('dirty');
            validate(c);
        });
    });
    const validate = (control) => {
        const fieldElement = control.closest('.field, label');
        if (fieldElement == null) {
            return;
        }
        control.setCustomValidity('');
        const validity = control.validity;
        if (validity.valid) {
            fieldElement.classList.remove('invalid');
        }
        else {
            fieldElement.classList.add('invalid');
        }
    };
}
